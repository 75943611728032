<template>
  <div class="">
    <Card class="p-m-2">
      <template #title>
        Bilgi Sistemi
      </template>
    </Card>
    <Card class="p-m-2">
      <template #title> Mükellef Listesi</template>
      <template #content>
        <Link_Datatable v-if="DataTable_render" :columns="columns" :data="MukellefListesi"
          v-on:selected_rows="getSelectedRows" :selectionmode="false" :detailbutton="false" datakey="rowid" />
      </template>
    </Card>
  </div>
</template>

<script>
import GlobalServis from "../../../services/GlobalServis";
import Link_Datatable from "../DataTable/Link_Datatable.vue";
export default {
  data() {
    return {
      DataTable_render: true,
      MukellefListesi: [],
      columns: [
        { field: "Donem", header: "Dönem" },
        { field: "Unvan", header: "Ünvan" },
      ],
    };
  },
  methods: {
    getDataChild(getdata_child) {
      console.log(getdata_child);

    },
    _GetMukellefTablosu() {
      GlobalServis.GlobalServis("GET", "GetTumMukellefTablosu", "").then(
        (res) => {
          if (res.status == 200) {
            this.MukellefListesi = res.data;
            console.log(this.MukellefListesi);

          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      )
    },
  },
  mounted() {
    this._GetMukellefTablosu();
  },
  components: {
    Link_Datatable
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.KullanimKart {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.233);
  text-align: center;
  background-color: #e6dfd8d3;
}

.Font600 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
}

.Font300 {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.Font300-link {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  font-style: normal;
}
</style>
