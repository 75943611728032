<template>
    <div>
        <DataTable :value="data" rowGroupMode="rowspan" groupRowsBy="Donem" sortMode="multiple" removableSort
                    v-model:filters="filtre" :globalFilterFields="FilterFilelds" responsiveLayout="scroll" class="p-datatable-sm" :paginator="true" :rows="10" :rowsPerPageOptions="[10, 20, 50]" 
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" resizableColumns columnResizeMode="fit" ref="dataTable" showGridlines   >
            <template #empty> Veri Bulunamadı. </template>
            <template #loading> Yükleniyor. Lüften Bekleyin. </template>
            <template #header>
                <div class="p-grid">
                    <div class="p-text-left p-col-6">
                        <Button icon="pi pi-external-link" label="Export"
                            class="p-button-raised p-button-rounded p-button-sm" @click="exportCSV($event)" />
                    </div>
                    <div class="p-text-right p-col-6">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filtre['global'].value" placeholder="Ara" />
                        </span>
                    </div>
                </div>
            </template>
            <Column sortable field="Donem" header="Dönem" style="max-width: 50px; background-color: lemonchiffon;" >
                <template #body="slotProps">
                    <div class="flex items-center gap-2">
                        {{ slotProps.data.Donem }}
                    </div>
                </template>
            </Column>
            <Column sortable field="OdemeGrup" header="Ödeme Grup"></Column>
            <Column sortable field="Unvan" header="Ünvan">
                <template #body="slotProps" >
                    <a :href="slotProps.data.Url" v-text="slotProps.data.Unvan" />
                </template>
            </Column> 
          
        </DataTable>
    
    
    </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
    props: {
        columns: Array,
        data: Array,
        selectionmode: Boolean,
        datakey: String,
        detailbutton: { default: true, type: Boolean },
    },
    data() {
        return {
            filtre: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            FilterFilelds: [],
            selectedrows: []
        };
    },
    methods: {
        exportCSV() {
            this.$refs.dataTable.exportCSV();
        },
        edit(EditRowData) {
            this.$emit("set-child-data", EditRowData);
        },
        onRowSelect() {
            this.$emit("selected_rows", this.selectedrows);
        },
        onRowUnselect() {
            this.$emit("selected_rows", this.selectedrows);
        }
    },
    mounted() {
        for (let index = 0; index < this.columns.length; index++) {
            this.FilterFilelds.push(this.columns[index].field);
        }
        window.$('.p-datatable-thead').css("z-index", "0");
        this.$emit("selected_rows", this.selectedrows);
    },
    created() { },
};
</script>